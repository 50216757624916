<template>

  <v-sheet elevation="0"
           v-if="loader.detail"
           :color="`grey darken-2 `"
           class="pa-0"
  >
    <v-skeleton-loader
      class="mx-auto"
      width="100%"
      type="card"
    ></v-skeleton-loader>
  </v-sheet>
  <v-card v-else>
    <v-card-title v-if="user_id && user_id == comicbook.user_id">
      <v-spacer></v-spacer>

      <v-btn color="warning" small class="ml-3"
             :to="'/comicbook/form/'+comicbook.id">
        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
        Editer
      </v-btn>
    </v-card-title>
    <div style="overflow: hidden; overflow-y: auto" class="position-relative">
      <v-img height="450" class=" align-end" style="position: sticky; top: 0"
             :src="comicbook.banner_src"
             cover
             :gradient="
             $vuetify.theme.dark ? 'to bottom, rgba(0,0,0,.0), rgba(0,0,0,.9)'
             :  'to bottom, rgba(255,255,255,.0), rgba(255,255,255,.9)' "
      >
        <div class="d-flex flex-column fill-height ">

        </div>
      </v-img>

<!--      <v-fade-transition>
        <v-overlay z-index="10"
          absolute
          color="dark"
        >
        </v-overlay>
      </v-fade-transition>-->

      <div style="top: 0; z-index: 3" class="d-flex flex-column fill-height position-absolute ">


        <v-row dense class=" pa-3 align-center">
          <v-col cols="12" lg="8">
            <div class="d-flex  align-center justify-space-around justify-end ">
              <div :class=" { 'mr-3':1, 'd-none': breakpoint.hidecover }">
                <v-img width="300" height="350" class=" align-end"
                       :src="comicbook.cover_src"
                       cover
                ></v-img>
              </div>
              <v-card elevation="0" cols="8">
                <v-card-subtitle color="deep-purple darken-3" class="mb-0 pb-0">
                <v-chip small color="whitesmook"
                        v-for="gender in comicbook.gender_names"
                        :key="gender.id"
                >
                  {{ gender.name }}
                </v-chip>
              </v-card-subtitle>

                <v-card-text>
                  <h1>
                    {{ comicbook.name }}
                  </h1>
                  <span>
            Par <router-link :to="'/profile/'+comicbook.id">
                    <v-list-item-avatar color="grey darken-3 ma-1">
                    <v-img
                      v-if="comicbook.user.avatar"
                      contain
                      class="elevation-6"
                      alt=""
                      :src="require('@/assets/images/avatars/'+comicbook.user.avatar)"
                    ></v-img> </v-list-item-avatar> <b>{{
                      comicbook.user.username
                    }}</b></router-link>
          </span>
                  <!--        <v-card-subtitle color="deep-purple darken-3" class="mb-0 pb-0">
                            ({{ comicbook.alternatif }})
                          </v-card-subtitle>-->
                  <p class="mt-6">
                    <read-more
                      :content="comicbook.synopsis" :max-length="120"></read-more>
                  </p>
                </v-card-text>
                <v-card-actions class="text-center py-0">
                  <span class="text-center">
                  <v-rating readonly
                            :value="parseFloat(comicbook.rate)"
                            hover
                            :size="21" dense
                            half-increments density="compact"
                            active-color="primary"
                  />
                 <small>{{ comicbook.rate }}/5 | {{ comicbook.nbvote }} Votes</small>

                  </span>
                  <v-spacer></v-spacer>
                  <p>{{ comicbook.nbsubscription }}<br>
                    <template v-if="comicbook.id">
                      <comicbook-follow-toggle-component
                        :status="comicbook.subscribed"
                        :user_id="parseInt(user_id)"
                        :comicbook_id="parseInt(comicbook.id)"
                        @initlogin="()=>$store.commit('updateAuthentication', true)"
                        @subscribed="onSubscribed"
                      ></comicbook-follow-toggle-component>
                    </template>
                  </p>
                </v-card-actions>
              </v-card>
            </div>

          </v-col>
          <v-col cols="12" lg="4">
            <v-card>
              <v-card-title color="deep-purple darken-3" class="  pb-2">
                3 derniers Chapitres
              </v-card-title>
              <v-card-text>
                <v-list dense class="pa-0">
                  <template v-for="(item, index) in chapters">
                    <!--              <v-divider
                                    :key="index"
                                  ></v-divider>-->

                    <v-list-item
                      :key="item.title" class="pa-0"
                      :to="'/scan/'+item.comicbook_seo+'/'+item.id"
                      dense
                    >
                      <img
                        class="mr-3"
                        width="50"
                        :src="item.cover_src_sm"
                      >

                      <v-list-item-content>
                        <v-list-item-title class="title-inline">
                          <b>#{{ item.number }}</b> {{ item.title }}
                          <v-spacer></v-spacer>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.creationdate }} -
                          <v-icon small>
                            {{ icons.mdiEyeOutline }}
                          </v-icon>
                          {{ item.nbviews }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-icon>
                      </v-list-item-icon>
                      <v-list-item-action>
                  <span
                    v-if="item.product.price == 0"
                    class="text--primary2  font-secondary"
                  >
                    Free
                  </span>
                        <template v-if="item.product.price > 0">
                          <v-chip
                            small
                            color="warning"
                          >
                            {{ item.product.price }} Credits
                          </v-chip>
                          Extrait {{ item.free_pages }} pages
                        </template>
                        <!--                  <toggle-favorite-component :nbkola="item.nbkola" :chapter="item"></toggle-favorite-component>-->
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

    </div>
    <!--    <v-card-title color="deep-purple darken-3">Synopsis</v-card-title>
        <v-card-text>
          {{ comicbook.synopsis }}
        </v-card-text>-->

    <v-row class="pt-3" dense>
      <v-col cols="12" md="7">
        <v-tabs class=" position-sticky font-secondary"
                v-model="tab" center-active
                align-tabs="center"
                grow
        >
          <v-tab
            key="illustrations"
          >
            Chapitres ({{ comicbook.nbchapter }})
          </v-tab>
          <v-tab
            key="series"
          >
            MiniChap ({{ comicbook.nbminichapter }})
          </v-tab>
          <v-tab
            key="oneshot"
          >
            Actualites
          </v-tab>
        </v-tabs>

        <template>

          <v-tabs-items v-model="tab">
            <v-tab-item
              key="illustrations"
            >
              <comic-chapter-component v-if="comicbook.id"
                                       :comicbook="comicbook"
                                       :user_id="user_id"
                                       :model="'complete'"
              ></comic-chapter-component>
            </v-tab-item>
            <v-tab-item
              :key="'series'"
            >

              <comic-chapter-component
                :comicbook="comicbook"
                :user_id="user_id"
                :model="'minichap'"
              ></comic-chapter-component>

            </v-tab-item>
            <v-tab-item
              :key="'oneshot'"
              style="min-height: 500px"
            >

              <comicbook-creator-activity-component
                v-if="comicbook.id"
                :user="user"
                :comicbook="comicbook"
                :parent_id="comicbook.post_id"
              ></comicbook-creator-activity-component>
            </v-tab-item>

          </v-tabs-items>
        </template>
      </v-col>
      <v-col cols="12" md="5">
        <v-card-text class="pb-3" v-if="comicbook.id">
          <comicbook-statistic-component
            :user_id="user_id"
            :comicbook="comicbook"
          ></comicbook-statistic-component>
        </v-card-text>
        <comicbook-comment-resume-component
          :comicbook="comicbook"
          :user="user"
          :url="`comicbook?cb_id=${comicbook.id}`"
        ></comicbook-comment-resume-component>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiExclamation,
  mdiInformationOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import {useRouter} from '@/utils'
import ToggleFavoriteComponent from '@/components/ToggleFavoriteComponent'
import ProfileCreatorComponent from "@/components/ProfileCreatorComponent.vue";
import CreatorSeriesComponent from "@/components/creator/CreatorSeriesComponent.vue";
import ProfilePostComponent from "@/components/ProfilePostComponent.vue";
import ComicChapterComponent from "@/components/comicbook/ComicChapterComponent.vue";
import PostReplyFormComponent from "@/components/PostReplyFormComponent.vue";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import ComicbookFollowToggleComponent
  from "@/components/comicbook/ComicbookFollowToggleComponent.vue";
import CommentComponent from "@/components/CommentComponent.vue";
import CommentItemComponent from "@/components/CommentItemComponent.vue";
import ComicbookCommentResumeComponent
  from "@/components/comicbook/ComicbookCommentResumeComponent.vue";
import store from "@/store";
import ComicbookCreatorActivityComponent
  from "@/components/comicbook/ComicbookCreatorActivityComponent.vue";
import PostComponent from "@/components/PostComponent.vue";
import ComicbookStatisticComponent from "@/components/comicbook/ComicbookStatisticComponent.vue";
import ReadMore from "@/components/ReadMore.vue";
import ImageUploadController from "@/components/ImageUploadController.vue";

export default {
  components: {
    ImageUploadController,
    ReadMore,
    ComicbookStatisticComponent,
    PostComponent,
    ComicbookCreatorActivityComponent,
    ComicbookCommentResumeComponent,
    CommentItemComponent,
    CommentComponent,
    ComicbookFollowToggleComponent,
    FollowToggleComponent,
    PostReplyFormComponent,
    ComicChapterComponent,
    ProfilePostComponent,
    CreatorSeriesComponent,
    ProfileCreatorComponent,
    ToggleFavoriteComponent
  },
// child
  metaInfo() {
    return {
      title: `${this.comicbook.name}`,
      meta: [

        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {
          vmid: 'og:url',
          property: 'og:url',
          content: Drequest.__env + `comicbook/${this.comicbook.nameseo}/${this.comicbook.id}`
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: `${this.comicbook.synopsis}`
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: `${this.comicbook.cover_src}`
        },

      ]
    }
  },
  setup(props, {emit}) {
    const $vuetify = getVuetify()

    const {router} = useRouter()
    const readMoreActivated = ref(false)
    const route = router.currentRoute
    const {id} = route.params
    const comicbook = ref({})
    const loader = ref({
      detail: true,
      loading: true,
    })
    const dialog = ref({
      newpost: false,
      replypost: false,
      imageviewer: false,
      authentication: false,
      commentpost: false,
      postkola: false,
    })
    const chapters = ref([])
    const tab = ref(0)

    let user_id;
    try {
      user_id = JSON.parse(localStorage.getItem('user_id'))
    } catch (e) {
      console.log(e)
    }
    const user = ref(Drequest.getUser())
    let url = ''
    if (user_id) {
      url = `&user_id=${user_id}`
    }
    loader.value.detail = true
    Drequest.api(`detail.comicbook?id=${id}` + url)
      .get(response => {
        console.log(response)
        loader.value.detail = false
        comicbook.value = response.comicbook

        emit('setTitle', comicbook.value.name, `/comicbook/${comicbook.value.nameseo}/${comicbook.value.id}`)
      })

    if (store.state.sub_id) {
      url += `&subscription_id=${store.state.sub_id}`
    }

    Drequest.api(`chapter.lazyloading?dfilters=on&next=1&dsort=id desc&per_page=3&status:eq=1&comicbook_id:eq=${id}${url}`)
      //.param({next: page})
      .get(response => {
        console.log(response)
        chapters.value = response.listEntity
      })
    /*

        loader.value.loading = true
        Drequest.api(`comicbook.complete-data?cb_id=${id}${url}`)
          .param({
          })
          .get(response => {
            console.log(response)
            loader.value.loading = false
            lastcomments.value = response.lastcomments
          })
    */

    const onSubscribed = (response) => {
      console.log(response)
    }

    const breakpoint = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return {
          hidecover: true
        }
      }
      if ($vuetify.breakpoint.xs) {
        return {
          hidecover: true
        }
      }

      return {
        hidecover: false
      }
    })

    const bannerUploaded = (response) => {
      console.log(response)
      comicbook.value.banner = response.comicbook.banner
      comicbook.value.path = response.comicbook.path
    }

    return {
      onSubscribed,
      bannerUploaded,

      dialog,
      loader,
      user_id,
      user,
      comicbook,
      chapters,
      breakpoint,
      tab,
      readMoreActivated,

      icons: {
        mdiExclamation,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
